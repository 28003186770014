import React from 'react';
import ReactDOM from 'react-dom';

interface AlertProps {
  type: 'success' | 'error' | 'info' | 'warning';
  message: string;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ type, message, onClose }) => {
  if (!message) return null;

  const alertTypeStyles = {
    success: 'bg-green-100 border-green-400 text-green-700',
    error: 'bg-red-100 border-red-400 text-red-700',
    info: 'bg-blue-100 border-blue-400 text-blue-700',
    warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className={`p-4 rounded shadow-lg ${alertTypeStyles[type]}`}>
        <div className="flex justify-between items-center">
          <span>{message}</span>
          <button onClick={onClose} className="text-lg font-bold ml-4">
            &times;
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Alert;
