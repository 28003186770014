import React from 'react';

interface ReusableButtonProps {
  onClick: () => void;
  loading: boolean;
  buttonText: string;
  disabled?: boolean;
}

const ReusableButton: React.FC<ReusableButtonProps> = ({ onClick, loading, buttonText }) => {
  return (
    <div className="flex justify-center items-center">
      <button 
        type="button" 
        className="primary"
        disabled={loading} 
        onClick={onClick}
      >
        {loading ? 'Processing...' : buttonText}
      </button>
    </div>
  );
};

const ReusableButton1: React.FC<ReusableButtonProps> = ({ onClick, loading, buttonText }) => {
  return (
    <div className="flex justify-center items-center">
      <button 
        type="button" 
        className="secondary"
        disabled={loading} 
        onClick={onClick}
      >
        {loading ? 'Processing...' : buttonText}
      </button>
    </div>
  );
};

const ReusableButtonLong: React.FC<ReusableButtonProps> = ({ onClick, loading, buttonText, disabled }) => {
  return (
    <div className="flex justify-center items-center">
      <button 
        type="submit" 
        className="long-primary"
        disabled={loading || disabled}
        onClick={onClick}
      >
        {loading ? 'Processing...' : buttonText}
      </button>
    </div>
  );
};

const ReusableButtonLong1: React.FC<ReusableButtonProps> = ({ onClick, loading, buttonText, disabled }) => {
  return (
    <div className="flex justify-center items-center">
      <button 
        type="submit" 
        className="long-secondary"
        disabled={loading || disabled}
        onClick={onClick}
      >
        {loading ? 'Processing...' : buttonText}
      </button>
    </div>
  );
};

export { ReusableButtonLong, ReusableButtonLong1, ReusableButton, ReusableButton1 };
