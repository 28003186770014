import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import Image from 'next/image';
import Head from 'next/head';
import { ReusableButtonLong, ReusableButtonLong1 } from "@/components/Button";
import Alert from '@/components/Alert';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface LoginPageProps {
  timestamp: number;
}

export const getStaticProps = async () => {
  const timestamp = Date.now(); // Generate timestamp once

  return {
    props: {
      timestamp,
    },
  };
};

export default function LoginPage({ timestamp }: LoginPageProps) {
  const router = useRouter();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<{ type: 'success' | 'error' | 'info' | 'warning', message: string }>({ type: 'info', message: '' });
  const [showPassword, setShowPassword] = useState(false);

  const onLogin = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setLoading(true);
      const response = await axios.post("/api/login", user);

      if (response.data.message) {
        setAlert({ type: 'success', message: 'Login successful!' });
        router.push('/staff');
      } else if (response.data.error === "Please check your inbox or spam folder to verify your email") {
        setAlert({ type: 'info', message: response.data.error });
        router.push('/reverify-email');
      } else {
        setAlert({ type: 'error', message: response.data.error });
      }
    } catch (error) {
      console.error(error);
      setAlert({ type: 'error', message: 'Internal Server Error. Please try again later.' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user.email.length > 0 && user.password.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [user]);

  return (
    <main>
      <Head>
        <title>Login</title>
        <meta name="description" content="Buy cheap data bundles, airtime online for MTN, Glo, Airtel, and 9mobile, cable tv subscriptions, electricity bills payment, education pins. Swiftly process automated services from all service providers." />
        <meta name="keywords" content="cheap-data-bundles, buy-data, airtime, top-up, MTN, Glo, Airtel, 9mobile, etisalat, VTU, cable-tv, dstv, gotv, startimes, showmax, electricity-paymenets, prepaid-meter, postpaid-meter, ikedc, ekedc, aedc, kedco, phed, jed, kaedco, eedc, ibedc, bedc, education-payments, exam-pins, neco, waec, jamb, utme, nabteb" />
      </Head>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Link href="/">
            <Image
              src={`/logo.webp?v=${timestamp}`}
              alt="iabcodes"
              width={200}
              height={100}
              className="mx-auto h-10 w-auto"
            />
          </Link>

          <h1 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-black">{loading ? "Processing" : "Sign in to your account"}</h1>
          <hr />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-black">Email Address</label>
              <div>
                <input
                  id="email"
                  type="email"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  placeholder="Email Address"
                  autoComplete="email"
                  className="primary"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-black">Password</label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={user.password}
                  onChange={(e) => setUser({ ...user, password: e.target.value })}
                  placeholder="Password"
                  autoComplete="current-password"
                  className="primary"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>

            <div>{alert.message && (<Alert type={alert.type} message={alert.message} onClose={() => setAlert({ type: 'info', message: '' })} />)}</div>

            {buttonDisabled ? <ReusableButtonLong1 onClick={onLogin} loading={loading} disabled={buttonDisabled} buttonText="Login" />
              : <ReusableButtonLong onClick={onLogin} loading={loading} disabled={buttonDisabled} buttonText="Login" />}
          </form>

          <div className="mt-10 flex justify-between space-x-4 text-center text-sm text-black">
            <p className="mt-10 text-center text-sm">
              Forgot password?{' '}
              <Link href="/forgot-password" className="font-semibold leading-6 text-primary hover:text-secondary">
                Click here
              </Link>
            </p>
            <p className="mt-10 text-center text-sm">
              Not a member?{' '}
              <Link href="/signup" className="font-semibold leading-6 text-primary hover:text-secondary">
                Signup
              </Link>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
